import { createAsyncThunk } from "@reduxjs/toolkit";
import { NotificationManager } from "react-notifications";

import { Node, Category } from "api/eventCategory/category.map";
import { deleteEventCategoryReq, getEventCategoryReq, updateEventCategoryReq } from "../../api/eventCategory";

export const getEventCategory = createAsyncThunk<Node<Category>, { id: string }>(
  "eventCategory/getEventCategory",
  async (data, { rejectWithValue }) => {
    try {
      const categoryDetails = await getEventCategoryReq(data.id);
      return categoryDetails;
    } catch (error: any) {
      return rejectWithValue(error.toString());
    }
  },
);

export const updateEventCategory = createAsyncThunk<any, any>("eventCategory/update", async (data, { rejectWithValue }) => {
  try {
    const response = await updateEventCategoryReq(data.id, data.data);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    if (error.response.status === 409) {
      NotificationManager.error("", "Можливо ви видалили категорію на котру вже є активні події", 3000);
    }
    return rejectWithValue(error.toString());
  }
});

export const deleteEventCategory = createAsyncThunk<any, any>("eventCategory/delete", async (data, { rejectWithValue }) => {
  try {
    const response = await deleteEventCategoryReq(data.id);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    return rejectWithValue(error.toString());
  }
});
