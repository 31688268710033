import "./match-modal.scss";

import React from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { NotificationManager } from "react-notifications";

import { Category, MatchLocalization } from "../tree";
import { LanguageButton } from "./language-button.component";
import { CopyButton } from "components/copy-button/copy-button.component";
import { TranslateButton } from "components/translate-button/translate-button.component";
import { createEmptyMatchLocalizations, getMatchTranslations, Language, languages } from "../translate";

enum ModalAction {
  Create = "Create",
  Edit = "Edit",
}

interface Props {
  addSubCategory: (parentId: string | null, category: Category) => void;
  editSubCategory: (category: Category) => void;
}

export interface AddMatchModalMethods {
  openMatchCreate: (parentId: string | null) => void;
  openMatchEdit: (category: Category) => void;
}

export const MatchModal = React.forwardRef<AddMatchModalMethods, Props>(({ addSubCategory, editSubCategory }, ref) => {
  const localizations = React.useRef<MatchLocalization[]>([]);

  const team1Input = React.useRef<HTMLInputElement>(null);
  const team2Input = React.useRef<HTMLInputElement>(null);
  const timeInput = React.useRef<HTMLInputElement>(null);

  const originalCategory = React.useRef<Category | null>(null);
  const parentCategoryId = React.useRef<string | null>(null);

  const [selectedLang, setSelectedLang] = React.useState<Language>(languages[0]);
  const [show, setShow] = React.useState(false);

  const actionType = !!originalCategory.current ? ModalAction.Edit : ModalAction.Create;

  React.useImperativeHandle(
    ref,
    () => ({
      openMatchCreate: (parentId: string | null) => {
        localizations.current = createEmptyMatchLocalizations();
        parentCategoryId.current = parentId;
        setShow(true);
      },
      openMatchEdit: (category: Category) => {
        setShow(true);
        originalCategory.current = category;
        localizations.current = category.match!.localization || createEmptyMatchLocalizations();
      },
    }),
    [],
  );

  const validateLocalization = () =>
    !localizations.current.some(({ team1, team2, time }) => team1.trim() === "" || team2.trim() === "" || time.trim() === "");

  const closeModal = () => {
    setShow(false);
    setSelectedLang(languages[0]);
    originalCategory.current = null;
    parentCategoryId.current = null;
    localizations.current = [];
  };

  const handleAction = () => {
    if (actionType === ModalAction.Create) {
      createMatch();
    } else {
      editMatch();
    }
  };

  const getInputValues = () => ({
    team1: team1Input.current!.value,
    team2: team2Input.current!.value,
    time: timeInput.current!.value,
  });

  const setLocalizationFromInputs = (language: Language) => {
    const index = localizations.current.findIndex((l) => l.language === language);
    if (index >= 0) {
      const { team1, team2, time } = getInputValues();
      localizations.current[index].team1 = team1;
      localizations.current[index].team2 = team2;
      localizations.current[index].time = time;
    }
  };

  const setInputValuesFromLocalization = (language: Language) => {
    const index = localizations.current.findIndex((l) => l.language === language);
    if (index >= 0) {
      const { team1, team2, time } = localizations.current[index];
      team1Input.current!.value = team1;
      team2Input.current!.value = team2;
      timeInput.current!.value = time;
    }
  };

  const createMatch = () => {
    setLocalizationFromInputs(selectedLang);
    const { team1, team2, time } = localizations.current[0];

    if (!validateLocalization()) {
      NotificationManager.error("", "Будь ласка введіть поля на всіх мовах 🙏", 2000);
      return;
    }

    addSubCategory(parentCategoryId.current, {
      categoryUuid: crypto.randomUUID(),
      categoryName: `${team1} - ${team2} [${time}]`,
      categoryColor: null,
      localizations: [],
      match: { team1, team2, time, localization: localizations.current },
    });

    closeModal();
  };

  const editMatch = () => {
    setLocalizationFromInputs(selectedLang);
    const { team1, team2, time } = localizations.current[0];

    if (!validateLocalization()) {
      NotificationManager.error("", "Будь ласка введіть поля на всіх мовах 🙏", 2000);
      return;
    }

    editSubCategory({
      categoryUuid: originalCategory.current!.categoryUuid,
      categoryName: `${team1} - ${team2} [${time}]`,
      categoryColor: null,
      localizations: [],
      match: { team1, team2, time, localization: localizations.current },
    });

    closeModal();
  };

  const handleCopy = () => {
    const { team1, team2, time } = getInputValues();
    localizations.current = languages.map((language) => ({ language, team1, team2, time }));
    setSelectedLang((selected) => selected);
  };

  const handleTranslate = async () => {
    const { team1, team2, time } = getInputValues();
    const translated = await getMatchTranslations(team1, team2, time);
    localizations.current = translated;
  };

  const handleSelectLanguage = (language: string) => {
    setLocalizationFromInputs(selectedLang);
    setInputValuesFromLocalization(language as Language);
    setSelectedLang(language as Language);
  };

  const renderLocalizationButton = (language: string) => (
    <LanguageButton key={`localization-input-${language}`} isActive={language === selectedLang} language={language} onSelect={handleSelectLanguage} />
  );

  return (
    <Modal show={show} onHide={closeModal}>
      <Modal.Header closeVariant="white" closeButton>
        <Modal.Title>Введіть команди та час</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body">
        <div className="localization-row">{languages.map(renderLocalizationButton)}</div>
        <div className="row-wrapper">
          <div className="input-label">
            <Form.Label htmlFor="Team1">Перша Команда</Form.Label>
          </div>
          <Form.Control
            autoFocus
            ref={team1Input}
            className="input"
            id="Team1"
            type="text"
            maxLength={95}
            defaultValue={originalCategory.current ? originalCategory.current.match!.team1 : ""}
          />
        </div>
        <div className="row-wrapper">
          <div className="input-label">
            <Form.Label htmlFor="Team2">Друга Команда</Form.Label>
          </div>
          <Form.Control
            autoFocus
            ref={team2Input}
            className="input"
            id="Team2"
            type="text"
            maxLength={95}
            defaultValue={originalCategory.current ? originalCategory.current.match!.team2 : ""}
          />
        </div>
        <div className="row-wrapper">
          <div className="input-label">
            <Form.Label htmlFor="time">Дата та час</Form.Label>
          </div>
          <Form.Control
            autoFocus
            ref={timeInput}
            className="input"
            id="time"
            type="text"
            maxLength={95}
            defaultValue={originalCategory.current ? originalCategory.current.match!.time : ""}
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="modal-footer">
        <div className="buttons-wrapper">
          <CopyButton onClick={handleCopy} />
          <TranslateButton onClick={handleTranslate} />
        </div>

        <div className="buttons-wrapper">
          <Button variant="danger" size="sm" onClick={closeModal}>
            Скасувати
          </Button>
          <Button variant="primary" size="sm" className="action-button" onClick={handleAction}>
            Зберегти
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
});
