import "./sub-category-row.scss";

import React from "react";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";

import { Node, Category } from "../tree";
import { MatchRow } from "../match-row/match-row.component";
import { ReactComponent as EditIcon } from "assets/controls/edit.svg";
import { ReactComponent as AddIcon } from "assets/controls/tiny-plus.svg";
import { SubCategories } from "../sub-categories/sub-categories.component";
import { ReactComponent as RemoveIcon } from "assets/controls/trash-can.svg";

interface Props {
  nestedIndex: number;
  category: Node<Category>;

  onAddCategory: (parentId: string | null, categoryLevel: number) => void;
  onEditSubCategory: (category: Category, categoryLevel: number) => void;
  onRemoveCategory: (parentId: string | null, categoryId: string) => void;
}

export const SubCategoryRow: React.FC<Props> = ({ nestedIndex, category, onAddCategory, onEditSubCategory, onRemoveCategory }) => {
  const handleAdd = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation();
    onAddCategory(category.id, nestedIndex + 1);
  };

  const handleEdit = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation();
    onEditSubCategory(category.data, nestedIndex);
  };

  const handleRemove = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation();
    onRemoveCategory(category.parentId, category.id);
  };

  if (category.children.length === 0 && !category.data.match) {
    return (
      <Card>
        <Card.Header className="leaf-header">
          {category.data.categoryName}
          <div className="controls">
            <EditIcon className="control-button" onClick={handleEdit} />
            <RemoveIcon className="control-button" onClick={handleRemove} />
            {nestedIndex < 3 ? <AddIcon className="control-button" onClick={handleAdd} /> : null}
          </div>
        </Card.Header>
      </Card>
    );
  }

  if (category.data.match) {
    return <MatchRow match={category.data.match} onEdit={handleEdit} onRemove={handleRemove} />;
  }

  return (
    <Accordion.Item eventKey={category.id}>
      <Accordion.Header className="accordion-header">
        <span className="category-name">{category.data.categoryName}</span>
        <div className="controls">
          <EditIcon className="control-button" onClick={handleEdit} />
          <RemoveIcon className="control-button" onClick={handleRemove} />
          {nestedIndex < 3 ? <AddIcon className="control-button" onClick={handleAdd} /> : null}
        </div>
      </Accordion.Header>

      <Accordion.Body style={{ paddingLeft: nestedIndex * 50 }}>
        <SubCategories
          nestedIndex={nestedIndex + 1}
          subCategories={category.children}
          onAddCategory={onAddCategory}
          onEditSubCategory={onEditSubCategory}
          onRemoveCategory={onRemoveCategory}
        />
      </Accordion.Body>
    </Accordion.Item>
  );
};
