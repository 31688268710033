import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { toasterCreator } from "../../utils/toasterCreator";
import { deleteEventCategory, updateEventCategory, getEventCategory } from "../actions/category";
import { createEventCategoryReq, getEventCategoryListReq } from "../../api/eventCategory";

const initialState = {
  eventCategory: null,
  eventCategoryList: [],
  isEventCategoryLoading: false,
  selectedCategoryId: null,
};

export const createEventCategory = createAsyncThunk("eventCategory/create", async (data, { rejectWithValue }) => {
  try {
    const response = await createEventCategoryReq(data);
    if (response.status === 200 || response.status === 201) {
      toasterCreator.events.categoryAdded();
      return response.data;
    }
  } catch (error) {
    return rejectWithValue(error.toString());
  }
});

export const getEventCategoryList = createAsyncThunk("eventCategory/getEventCategoryList", async (_, { rejectWithValue }) => {
  try {
    const response = await getEventCategoryListReq();
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error) {
    return rejectWithValue(error.toString());
  }
});

export const setEventCategorySelected = (id) => {
  return {
    type: "eventCategory/SET_ITEM_SELECTED",
    id,
  };
};

export const eventCategorySlice = createSlice({
  name: "eventCategory",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createEventCategory.pending, (state) => {
        state.isEventCategoryLoading = true;
      })
      .addCase(createEventCategory.fulfilled, (state, action) => {
        state.eventCategoryList = [...state.eventCategoryList, action.payload];
        state.isEventCategoryLoading = false;
      })
      .addCase(createEventCategory.rejected, (state) => {
        state.isEventCategoryLoading = false;
      })

      .addCase(updateEventCategory.pending, (state) => {
        state.isEventCategoryLoading = true;
      })
      .addCase(updateEventCategory.fulfilled, (state, action) => {
        state.eventCategoryList = state.eventCategoryList.map((item) => {
          if (item.categoryUuid === action.payload.categoryUuid) {
            return {...item, ...action.payload.data};
          }

          return item;
        });
        state.isEventCategoryLoading = false;
      })
      .addCase(updateEventCategory.rejected, (state) => {
        state.isEventCategoryLoading = false;
      })

      .addCase(deleteEventCategory.pending, (state, action) => {
        state.eventCategoryList = state.eventCategoryList.filter(c => c.categoryUuid !== action.meta.arg.id);
        state.isEventCategoryLoading = true;
      })
      .addCase(deleteEventCategory.fulfilled, (state) => {
        state.isEventCategoryLoading = false;
      })
      .addCase(deleteEventCategory.rejected, (state) => {
        state.isEventCategoryLoading = false;
      })

      .addCase(getEventCategory.pending, (state) => {
        state.isEventCategoryLoading = true;
      })
      .addCase(getEventCategory.fulfilled, (state, action) => {
        state.isEventCategoryLoading = false;
      })
      .addCase(getEventCategory.rejected, (state) => {
        state.isEventCategoryLoading = false;
      })

      .addCase(getEventCategoryList.pending, (state) => {
        state.isEventCategoryLoading = true;
      })
      .addCase(getEventCategoryList.fulfilled, (state, action) => {
        state.eventCategoryList = action.payload;
        state.isEventCategoryLoading = false;
      })
      .addCase(getEventCategoryList.rejected, (state) => {
        state.eventCategoryList = [];
        state.isEventCategoryLoading = false;
      })

      .addCase("eventCategory/SET_ITEM_SELECTED", (state, action) => {
        state.selectedCategoryId = action.id;
      });
  },
});

export const selectisEventCategoryLoading = (state) => state.eventCategory?.isEventCategoryLoading;
export const selectEventInfo = (state) => state.eventCategory?.event;
export const selectEventCategoryList = (state) => state.eventCategory?.eventCategoryList;
export const selectSelectedEventCategoryId = (state) => state.eventCategory?.selectedCategoryId;

export default eventCategorySlice.reducer;
