import "./translate-button.scss";

import React from "react";
import Button from "react-bootstrap/Button";

import { ReactComponent as TranslateIcon } from "../../assets/controls/autotranslate.svg";

interface Props {
  onClick: () => void;
}

export const TranslateButton: React.FC<Props> = ({ onClick }) => (
  <Button variant="primary" size="sm" className="additional-button" onClick={onClick}>
    <TranslateIcon /> Перекласти
  </Button>
);
