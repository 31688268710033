import React from "react";
import Button from "react-bootstrap/Button";

interface Props {
  language: string;
  isActive?: boolean;

  onSelect: (language: string) => void;
}

export const LanguageButton: React.FC<Props> = ({ language, isActive, onSelect }) => {
  const handleSelect = () => onSelect(language);

  return (
    <Button variant={isActive ? "success" : "primary"} size="sm" style={{ color: "white" }} onClick={handleSelect}>
      {language}
    </Button>
  );
};
