import "./name-row.scss";

import React from "react";
import Form from "react-bootstrap/Form";

interface Props {
  language: string;
  defaultValue?: string;
}

export const NameRow = React.forwardRef<HTMLInputElement, Props>(({ language, defaultValue }, ref) => (
  <div className="row-wrapper">
    <div id="input-wrapper">
      <Form.Label htmlFor={`categoryName-${language}`}>{language.toUpperCase()}</Form.Label>
    </div>
    <Form.Control autoFocus className="input" id="categoryName" ref={ref} type="text" maxLength={95} defaultValue={defaultValue} />
  </div>
));
