import React from "react";
import Accordion from "react-bootstrap/Accordion";

import { Node, Category } from "../tree";
import { SubCategoryRow } from "../sub-category-row/sub-category-row.component";

interface Props {
  nestedIndex: number;
  subCategories: Node<Category>[];

  onAddCategory: (parentId: string | null, categoryLevel: number) => void;
  onEditSubCategory: (category: Category, categoryLevel: number) => void;
  onRemoveCategory: (parentId: string | null, categoryId: string) => void;
}

export const SubCategories: React.FC<Props> = ({ nestedIndex, subCategories, onAddCategory, onEditSubCategory, onRemoveCategory }) => {
  const renderSubCategory = (categoryNode: Node<Category>) => (
    <SubCategoryRow
      key={`Category-row-${categoryNode.id}`}
      nestedIndex={nestedIndex}
      category={categoryNode}
      onAddCategory={onAddCategory}
      onEditSubCategory={onEditSubCategory}
      onRemoveCategory={onRemoveCategory}
    />
  );

  return (
    <Accordion alwaysOpen style={nestedIndex === 1 ? { width: "75%" } : {}}>
      {subCategories.map(renderSubCategory)}
    </Accordion>
  );
};
