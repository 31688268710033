import React from "react";

import { buildNodeCategory, Category, getAllCategoriesNodes, Node } from "./tree";

interface CategoriesTreeParams {
  rootCategoryId: string | null;
  categories: Node<Category>[];
}

export const useCategoriesTree = ({ rootCategoryId = null, categories = [] }: CategoriesTreeParams) => {
  const rootId = React.useRef<string | null>(rootCategoryId);
  const allNodes = React.useRef(getAllCategoriesNodes(categories));
  const [tree, setTree] = React.useState<Node<Category>[]>(categories);

  const updateCategories = (categories: Node<Category>[]) => {
    allNodes.current = getAllCategoriesNodes(categories);
    setTree(categories);
  };

  const updateRootCategory = (rootCategoryId: string | null) => rootId.current = rootCategoryId;

  const addNode = (parentId: string | null, category: Category) => {
    const node = buildNodeCategory(parentId, category);
    allNodes.current.set(node.id, node);

    if (parentId === rootId.current) {
      tree.push(node);
    } else {
      if (!allNodes.current.get(parentId!)) {
        throw new Error("No category with this ID in tree!");
      }

      allNodes.current.get(parentId!)!.children.push(node);
    }

    setTree((tree) => [...tree]);
  };

  const deleteNodeAndChildren = (nodeId: string) => {
    const node = allNodes.current.get(nodeId);

    if (node) {
      node.children.forEach((child) => deleteNodeAndChildren(child.id));
      allNodes.current.delete(nodeId);
    }
  };

  const removeNode = (parentId: string | null, categoryId: string) => {
    deleteNodeAndChildren(categoryId);
    
    const childrenArray = (parentId === rootId.current ? tree : allNodes.current.get(parentId!)?.children) || [];
    
    const index = childrenArray.findIndex((n) => n.id === categoryId);
    if (index !== -1) {
      childrenArray.splice(index, 1);
    }

    setTree((tree) => [...tree]);
  };

  const editNode = (category: Category) => {
    const node = allNodes.current.get(category.categoryUuid);
    if (node) {
      node.data = category;
    } else {
      throw new Error(`Node with ID ${category.categoryUuid} not found`);
    }
    setTree((tree) => [...tree]);
  };

  return { tree, addNode, removeNode, editNode, updateCategories, updateRootCategory };
};
