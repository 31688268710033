import "./edit-sub-category-modal.scss";

import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { NotificationManager } from "react-notifications";

import { Category } from "../tree";
import { NameRow } from "../name-row/modal-name-row.component";
import { getTranslations, type Language, languages } from "../translate";
import { CopyButton } from "components/copy-button/copy-button.component";
import { TranslateButton } from "components/translate-button/translate-button.component";

interface Props {
  editSubCategory: (category: Category) => void;
}

export interface EditSubCategoryModalMethods {
  openSubCategoryEdit: (category: Category) => void;
}

export const EditSubCategoryModal = React.forwardRef<EditSubCategoryModalMethods, Props>(({ editSubCategory }, ref) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const categoryNameInputsMap = new Map(languages.map((language) => [language, React.useRef<HTMLInputElement>(null)]));

  const originalCategory = React.useRef<Category | null>(null);

  const [show, setShow] = React.useState(false);

  const closeModal = () => setShow(false);

  const clearInputs = () => {
    categoryNameInputsMap.forEach((input) => {
      input.current!.value = "";
    });
  };

  const validateLocalization = (inputsValues: ReturnType<typeof getNameLocalization>) => {
    return !inputsValues.some((localization) => localization.name.trim() === "");
  };

  const getNameLocalization = () => {
    const localizations = languages.map((language) => ({
      language,
      name: categoryNameInputsMap.get(language)!.current?.value || "",
    }));

    return localizations;
  };

  const getFirstLangWithTextIndex = () => languages.findIndex((language) => categoryNameInputsMap.get(language)!.current!.value.trim() !== "");

  const handleCopy = () => {
    const langIndex = getFirstLangWithTextIndex();

    if (langIndex === -1) {
      return;
    }

    const languageForCopy = languages[langIndex];
    const valueForCopy = categoryNameInputsMap.get(languageForCopy)!.current!.value;

    languages.forEach((language) => {
      if (language !== languageForCopy) {
        categoryNameInputsMap.get(language)!.current!.value = valueForCopy;
      }
    });
  };

  const handleTranslate = async () => {
    const langIndex = getFirstLangWithTextIndex();

    if (langIndex === -1) {
      return;
    }

    const languageForTranslate = languages[langIndex];
    const valueForTranslate = categoryNameInputsMap.get(languageForTranslate)!.current!.value;

    const translations = await getTranslations(valueForTranslate);
    translations.forEach(({ language, name }) => {
      categoryNameInputsMap.get(language)!.current!.value = name;
    });
  };

  const handleCreate = () => {
    const categoryName = categoryNameInputsMap.get("uk")!.current?.value || "";

    const localizations = getNameLocalization();

    if (!validateLocalization(localizations)) {
      NotificationManager.error("", "Будь ласка перекладіть назву на всі мови 🙏", 2000);
      return;
    }

    if (originalCategory.current) {
      editSubCategory({
        ...originalCategory.current,
        categoryName,
        localizations: localizations,
      });
    }
    closeModal();
    clearInputs();
  };

  React.useImperativeHandle(
    ref,
    () => ({
      openSubCategoryEdit: (category: Category) => {
        setShow(true);
        originalCategory.current = category;
      },
    }),
    [],
  );

  const renderNameInput = (language: Language) => (
    <NameRow
      key={`input-${language}`}
      language={language}
      ref={categoryNameInputsMap.get(language)}
      defaultValue={originalCategory.current?.localizations.find((l) => l.language === language)?.name}
    />
  );

  return (
    <Modal show={show} onHide={closeModal}>
      <Modal.Header closeVariant="white" closeButton>
        <Modal.Title>Змініть назву підкатегорії</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body">{languages.map(renderNameInput)}</Modal.Body>
      <Modal.Footer className="modal-footer">
        <div className="buttons-wrapper">
          <CopyButton onClick={handleCopy} />
          <TranslateButton onClick={handleTranslate} />
        </div>

        <div className="buttons-wrapper">
          <Button variant="danger" size="sm" onClick={closeModal}>
            Скасувати
          </Button>
          <Button variant="primary" size="sm" className="action-button" onClick={handleCreate}>
            Зберегти
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
});
