import "./index.scss";

import React, { PropsWithChildren, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Loader from "../../components/Loader";
import { MainCategory } from "./EditCategory/tree";
import Tips, { TIPS_TYPE, TipsType } from "../../containers/Tips";
import { EditCategory } from "./EditCategory/edit-category.component";
import { getEventCategoryList, selectSelectedEventCategoryId } from "../../store/slices/eventCategory";

import Icon2 from "../../assets/home/animale.png";
import dogTip1 from "../../assets/events/dog-1.png";

const Events: React.FC<PropsWithChildren> = ({ children }) => {
  const categories = React.useRef<MainCategory[]>([]);
  const [tipType, setTipType] = useState<TipsType>(TIPS_TYPE.CHOOSE);

  const dispatch = useDispatch();
  const isEventCategoryLoading = useSelector((state: any) => state.eventCategory.isEventCategoryLoading);
  const selectedCategoryUUID = useSelector(selectSelectedEventCategoryId);
  const fetchCategoryList = async () => {
    // @ts-ignore
    categories.current = await dispatch(getEventCategoryList()).unwrap();
  };

  const selectedCategory = categories.current.find((category: any) => category.categoryUuid === selectedCategoryUUID);

  useEffect(() => {
    fetchCategoryList();
  }, []);

  useEffect(() => {
    if (selectedCategoryUUID && !children) {
      setTipType(TIPS_TYPE.CHOOSE_PART);
    } else if (!selectedCategoryUUID && !children) {
      setTipType(TIPS_TYPE.CHOOSE);
    }
  }, [selectedCategoryUUID, children]);

  return (
    <div className="events">
      {children && children}
      {!children && selectedCategory && <EditCategory category={selectedCategory!} />}
      {!children && !selectedCategory && (
        <>
          <div className="events__tips">
            <Tips type={tipType} title="" />
          </div>
          <img className="icon2" src={tipType === TIPS_TYPE.CHOOSE ? Icon2 : dogTip1} alt="choose-icon-2" />
        </>
      )}
      {isEventCategoryLoading && <Loader />}
    </div>
  );
};

export default Events;
