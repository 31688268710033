import React from "react";
import Accordion from "react-bootstrap/Accordion";

import { Node, Category } from "pages/Events/EditCategory/tree";
import { SubCategoriesRow } from "../sub-categories-row/sub-categories-row.component";

interface Props {
  nestedIndex: number;
  selectedCategoryId: string | null;
  subCategories: Node<Category>[];

  onSelectCategory: (id: string | null) => void;
}

export const SubCategoriesSelect: React.FC<Props> = ({
  nestedIndex,
  selectedCategoryId,
  subCategories = [],
  onSelectCategory,
}) => {
  const renderSubCategory = (categoryNode: Node<Category>) => (
    <SubCategoriesRow
      key={`Category-row-${categoryNode.id}`}
      category={categoryNode}
      nestedIndex={nestedIndex}
      selectedCategoryId={selectedCategoryId}
      onSelectCategory={onSelectCategory}
    />
  );

  return (
    <Accordion alwaysOpen style={nestedIndex === 1 ? { width: "75%" } : {}}>
      {subCategories.map(renderSubCategory)}
    </Accordion>
  );
};
