import React from "react";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";

import { Node, Category } from "pages/Events/EditCategory/tree";
import { SubCategoriesSelect } from "../sub-categories-select/sub-categories-select.component";

interface Props {
  category: Node<Category>;
  nestedIndex: number;
  selectedCategoryId: string | null;

  onSelectCategory: (id: string | null) => void;
}

export const SubCategoriesRow: React.FC<Props> = ({ category, nestedIndex, selectedCategoryId, onSelectCategory }) => {
  const isCategoryActive = category.id === selectedCategoryId;
  const [cardStyle, headerStyle] = isCategoryActive ? [{ backgroundColor: "#3ddde8" }, { color: "black" }] : [{}, {}];

  const handleSelectCategory = () => onSelectCategory(category.id);

  if (category.children.length === 0) {
    return (
      <Card style={cardStyle} onClick={handleSelectCategory}>
        <Card.Header style={headerStyle}>{category.data.categoryName}</Card.Header>
      </Card>
    );
  }

  return (
    <Accordion.Item eventKey={category.id}>
      <Accordion.Header>
        <span>{category.data.categoryName}</span>
      </Accordion.Header>

      <Accordion.Body style={{ paddingLeft: nestedIndex * 50 }}>
        <SubCategoriesSelect
          nestedIndex={nestedIndex + 1}
          selectedCategoryId={selectedCategoryId}
          subCategories={category.children}
          onSelectCategory={onSelectCategory}
        />
      </Accordion.Body>
    </Accordion.Item>
  );
};
