import "./copy-button.scss";

import React from "react";
import Button from "react-bootstrap/Button";

import { ReactComponent as CopyIcon } from "../../assets/controls/copy.svg";

interface Props {
  onClick: () => void;
}

export const CopyButton: React.FC<Props> = ({ onClick }) => (
  <Button variant="success" size="sm" className="additional-button" onClick={onClick}>
    <CopyIcon /> Скопіювати
  </Button>
);
