import React from "react";

import { CATEGORY_COLORS } from "constants/colors";

interface Props {
  selectedColor: string | null;

  onSelectColor: (color: string) => void;
}

export const BlockColor: React.FC<Props> = ({ selectedColor, onSelectColor }) => {
  return CATEGORY_COLORS.map((item) => (
    <div
      key={`gradient-color-${item.id}`}
      className={`block-with-color__item ${selectedColor === item.color ? "selected" : ""}`}
      tabIndex={0}
      style={{ background: item.color }}
      onClick={() => {
        onSelectColor(item.color);
      }}
      onKeyDown={(e) => {
        if (e.keyCode === 13) {
          e.preventDefault();
          onSelectColor(item.color);
        }
      }}
    ></div>
  ));
};
