import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Loader from "../../../components/Loader";
import StepsNav from "../../../containers/StepsNav";
import { Node, Category } from "../EditCategory/tree";
import { getEventCategory } from "store/actions/category";
import FinishStepPage from "containers/StepsPage/FinishStepPage";
import EndRefPage from "../../../containers/StepsPage/EndRefPage";
import Tips, { TIPS_TYPE, TipsType } from "../../../containers/Tips";
import StartRefPage from "../../../containers/StepsPage/StartRefPage";
import ImageStepPage from "../../../containers/StepsPage/ImageStepPage";
import ContextStepPage from "../../../containers/StepsPage/ContextStepPage";
import TimeRangeStepPage from "../../../containers/StepsPage/TimeRangeStepPage";
import EventDescStepPage from "../../../containers/StepsPage/EventDescStepPage";
import SubcategoryStepPage from "../../../containers/StepsPage/SubcategoryStepPage";
import { SubCategoriesTree } from "containers/StepsPage/sub-categories-tree/sub-categories-tree.component";
import { selectSelectedEventCategoryId, setEventCategorySelected } from "../../../store/slices/eventCategory";
import { clearPagesReduxState, initPageData, selectIsReadyToSubmit, selectStoredCategoryId, setToDefault, updateSteps } from "../../../store/slices/steps";

import dogTip1 from "../../../assets/events/dog-1.png";
import dogTip2 from "../../../assets/events/dog-2.png";

import "./index.scss";

const AddEvent = () => {
  const { t } = useTranslation(["event"]);
  const dispatch = useDispatch();
  const categoriesTree = React.useRef<Node<Category>[]>([]);
  const [tipType, setTipType] = useState<TipsType>();
  const [activeStep, setActiveStep] = useState<number | null>(null);
  const isEventCategoryLoading = useSelector((state: any) => state.eventCategory.isEventCategoryLoading);
  const selectedCategoryId = useSelector(selectSelectedEventCategoryId);
  const isReadyToSubmitData = useSelector(selectIsReadyToSubmit);
  const storedCategoryId = useSelector(selectStoredCategoryId);

  const cancelHandler = () => {
    setActiveStep(null);
    dispatch(setToDefault());
    dispatch(setEventCategorySelected(null));
  };

  const isNeedToClearSelectedStep = () => {
    if (!selectedCategoryId && activeStep) {
      setActiveStep(null);
    }
  };

  const setTip = () => {
    if (selectedCategoryId) {
      setTipType(TIPS_TYPE.CLICK);
    } else {
      setTipType(TIPS_TYPE.CHOOSE_CATEGORY);
    }
  };

  const fetchCategoryInfo = async () => {
    console.log({selectedCategoryId});
    const categoryInfo = await dispatch(getEventCategory({ id: selectedCategoryId }) as any).unwrap();

    categoriesTree.current = categoryInfo.children;
    const isCategoryHaveSubCategoriesTree = categoryInfo.children.length > 0;

    dispatch(updateSteps(isCategoryHaveSubCategoriesTree));
  };

  useEffect(() => {
    if (selectedCategoryId) { 
      fetchCategoryInfo();
    }
    if (selectedCategoryId && activeStep) {
      return;
    }
    isNeedToClearSelectedStep();
    setTip();
  }, [selectedCategoryId]);

  useEffect(() => {
    document.title = t("addEvent");
    dispatch(initPageData());

    return () => {
      dispatch(clearPagesReduxState());
    };
  }, []);

  useEffect(() => {
    if (storedCategoryId) {
      dispatch(setEventCategorySelected(storedCategoryId));
    }
  }, [storedCategoryId]);

  return (
    <div className="add-event">
      <div className="content">
        <div className="content__steps">
          {selectedCategoryId && !isReadyToSubmitData && (
            <StepsNav disabledBtn={false} activeStep={activeStep} onStepChange={setActiveStep} onCancel={cancelHandler} />
          )}
          {!activeStep && !isReadyToSubmitData && (
            <div className="content__tips">
              <Tips type={tipType} title={t("addEvent")} />
            </div>
          )}
          {(activeStep || isReadyToSubmitData) && (
            <div className="content__pages">
              {activeStep === 1 && <SubCategoriesTree id={1} categoriesTree={categoriesTree.current} />}
              {activeStep === 2 && <SubcategoryStepPage id={2} />}
              {activeStep === 3 && <EventDescStepPage id={3} />}
              {activeStep === 4 && <ContextStepPage id={4} />}
              {activeStep === 5 && <TimeRangeStepPage id={5} />}
              {activeStep === 6 && <StartRefPage id={6} />}
              {activeStep === 7 && <EndRefPage id={7} />}
              {activeStep === 8 && <ImageStepPage id={8} setActiveStep={setActiveStep} />}
              {isReadyToSubmitData && <FinishStepPage setActiveStep={setActiveStep} />}
            </div>
          )}
        </div>
      </div>
      {!activeStep && !isReadyToSubmitData && (
        <div className="add-event__img">
          <img
            src={tipType === TIPS_TYPE.CLICK ? dogTip2 : dogTip1}
            alt="choose-icon-2"
            style={{
              left: tipType === TIPS_TYPE.CLICK ? "471px" : "unset",
            }}
          />
        </div>
      )}
      {isEventCategoryLoading && <Loader />}
    </div>
  );
};

export default AddEvent;
