import "./match-row.scss";

import React from "react";
import Card from "react-bootstrap/Card";

import { Category } from "../tree";
import { ReactComponent as EditIcon } from "assets/controls/edit.svg";
import { ReactComponent as RemoveIcon } from "assets/controls/trash-can.svg";

interface Props {
  match: Exclude<Category["match"], undefined>;

  onEdit: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
  onRemove: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
}

export const MatchRow: React.FC<Props> = ({ match, onEdit, onRemove }) => (
  <Card>
    <Card.Header className="header">
      <div className="info">
        <div>{match.team1}</div>
        <div>{match.team2}</div>
        <div>{match.time}</div>
      </div>
      <div className="actions">
        <EditIcon className="control-button" onClick={onEdit} />
        <RemoveIcon className="control-button" onClick={onRemove} />
      </div>
    </Card.Header>
  </Card>
);
