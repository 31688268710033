import api from "../api";

class StatisticService {
  public getRegisteredUsers = async (startDate: Date, endDate: Date) => {
    const [from, to] = [startDate.toISOString().slice(0, 10), endDate.toISOString().slice(0, 10)];

    console.log({ from, to });

    const res = await api.get("/admin/users/statistic", {
      responseType: "blob",
      params: { from, to },
    });

    const url = window.URL.createObjectURL(res.data);

    return url;
  };
}

export const statisticService = new StatisticService();
