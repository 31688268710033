import React, { useState } from 'react';

const CustomAccordion = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div style={{ marginBottom: '10px', border: '1px solid #ccc', borderRadius: '4px' }}>
            <div
                onClick={toggleAccordion}
                style={{
                    padding: '10px',
                    backgroundColor: '#f1f1f1',
                    cursor: 'pointer',
                    fontWeight: 'bold',
                    color: '#000000',
                }}
            >
                {title}
            </div>
            {isOpen && <div style={{ padding: '10px' }}>{children}</div>}
        </div>
    );
};

export default CustomAccordion;
