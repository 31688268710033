import API from "../api";
import { CategoryDTO } from "./category.dto";
import { mapCategoryTree } from "./category.map";

export const createEventCategoryReq = (data: any) => API.post<CategoryDTO>("/admin/event/category-v2", data);

export const updateEventCategoryReq = (id: string, data: any) => API.put<CategoryDTO>(`/admin/event/category-v2/${id}`, data);

export const deleteEventCategoryReq = (id: string) => API.delete(`/admin/event/category-v2/${id}`);

export const getEventCategoryReq = async (id: string) => {
  const res = await API.get<CategoryDTO>(`/admin/event/category-v2/${id}`);
  return mapCategoryTree(res.data);
};

export const getEventCategoryListReq = () => API.get(`/admin/event/category/list`);
