import "./edit-category.scss";

import React from "react";
import { useDispatch } from "react-redux";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";

import { Category, MainCategory } from "./tree";
import { NameRow } from "./name-row/modal-name-row.component";
import { useCategoriesTree } from "./use-categories-tree.hook";
import { getTranslations, Language, languages } from "./translate";
import { setEventCategorySelected } from "store/slices/eventCategory";
import { SubCategories } from "./sub-categories/sub-categories.component";
import { BlockColor } from "components/block-color/block-color.component";
import { CopyButton } from "components/copy-button/copy-button.component";
import { ReactComponent as PlusIcon } from "../../../assets/controls/plus-rounded.svg";
import { MatchModal, AddMatchModalMethods } from "./match-modal/match-modal.component";
import { TranslateButton } from "components/translate-button/translate-button.component";
import { deleteEventCategory, getEventCategory, updateEventCategory } from "store/actions/category";
import { AddSubCategoryModal, AddSubCategoryModalMethods } from "./add-sub-category-modal/add-sub-category-modal.component";
import { EditSubCategoryModal, EditSubCategoryModalMethods } from "./edit-sub-category-modal/edit-sub-category-modal.component";

interface Props {
  category: MainCategory;
}

export const EditCategory: React.FC<Props> = ({ category }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const categoryNameInputsMap = new Map(languages.map((language) => [language, React.useRef<HTMLInputElement>(null)]));

  const addCategoryModal = React.useRef<AddSubCategoryModalMethods>(null);
  const editCategoryModal = React.useRef<EditSubCategoryModalMethods>(null);
  const addMatchModal = React.useRef<AddMatchModalMethods>(null);

  const { t } = useTranslation(["button", "common"]);
  const dispatch = useDispatch();

  const { tree, addNode, removeNode, editNode, updateCategories, updateRootCategory } = useCategoriesTree({
    rootCategoryId: category.categoryUuid,
    categories: [],
  });

  const [selectedColor, setSelectedColor] = React.useState<string | null>(category.categoryColor);
  // category.categoryName

  const getFirstLangWithTextIndex = () => languages.findIndex((language) => categoryNameInputsMap.get(language)!.current!.value.trim() !== "");

  const handleCopy = () => {
    const langIndex = getFirstLangWithTextIndex();

    if (langIndex === -1) {
      return;
    }

    const languageForCopy = languages[langIndex];
    const valueForCopy = categoryNameInputsMap.get(languageForCopy)!.current!.value;

    languages.forEach((language) => {
      if (language !== languageForCopy) {
        categoryNameInputsMap.get(language)!.current!.value = valueForCopy;
      }
    });
  };

  const handleTranslate = async () => {
    const langIndex = getFirstLangWithTextIndex();

    if (langIndex === -1) {
      return;
    }

    const languageForTranslate = languages[langIndex];
    const valueForTranslate = categoryNameInputsMap.get(languageForTranslate)!.current!.value;

    const translations = await getTranslations(valueForTranslate);
    translations.forEach(({ language, name }) => {
      categoryNameInputsMap.get(language)!.current!.value = name;
    });
  };

  const setCategoryLocalization = (category: MainCategory) => {
    const localizations = category.data?.localizations || [];

    languages.forEach((language) => {
      const input = categoryNameInputsMap.get(language as Language);
      if (input) {
        input.current!.value = "";
      }
    });

    categoryNameInputsMap.get("en")!.current!.value = category.categoryName;

    localizations.forEach((localization) => {
      const input = categoryNameInputsMap.get(localization.language as Language);
      if (input) {
        input.current!.value = localization.name;
      }
    });
  };

  const getNameLocalization = () => languages.map((language) => ({ language, name: categoryNameInputsMap.get(language)!.current?.value || "" }));

  const validateLocalization = (inputsValues: ReturnType<typeof getNameLocalization>) => {
    return !inputsValues.some((localization) => localization.name.trim() === "");
  };

  const saveChanges = async () => {
    const categoryName = categoryNameInputsMap.get("en")!.current?.value || "";
    const localizations = getNameLocalization();

    if (!validateLocalization(localizations)) {
      NotificationManager.error("", "Будь ласка перекладіть назву на всі мови 🙏", 2000);
      return;
    }

    const updateAction = updateEventCategory({
      id: category.categoryUuid,
      data: {
        parentCategoryUuid: null,

        categoryName,
        categoryColor: selectedColor,
        data: {
          categoryUuid: category.categoryUuid,
          categoryName,
          categoryColor: selectedColor,
          localizations,
        },

        children: tree.map((subCategory) => ({ ...subCategory, parentCategoryUuid: category.categoryUuid })),
      },
    });

    await dispatch(updateAction as any).unwrap();
  };

  const deleteCategory = async () => {
    await dispatch(deleteEventCategory({ id: category.categoryUuid }) as any).unwrap();
    dispatch(setEventCategorySelected(null));
  };

  const fetchCategoryInfo = async () => {
    const res = await dispatch(getEventCategory({ id: category.categoryUuid }) as any).unwrap();
    updateRootCategory(category.categoryUuid);
    updateCategories(res.children);
  };

  const addRootSubCategory = () => onAddSubCategory(category.categoryUuid, 0);

  const onAddSubCategory = (parentId: string | null, categoryLevel: number) => {
    if (categoryLevel === 3) {
      addMatchModal.current?.openMatchCreate(parentId);
      return;
    }

    addCategoryModal.current?.openSubCategoryCreate(parentId);
  };

  const onEditSubCategory = (category: Category, categoryLevel: number) => {
    if (categoryLevel === 3) {
      addMatchModal.current?.openMatchEdit(category);
      return;
    }

    editCategoryModal.current?.openSubCategoryEdit(category);
  };

  React.useEffect(() => {
    setSelectedColor(category.categoryColor);
    fetchCategoryInfo();

    setCategoryLocalization(category);
  }, [category]);

  const renderNameInput = (language: Language) => (
    <NameRow key={`main-category-input-${language}`} language={language} ref={categoryNameInputsMap.get(language)} />
  );

  return (
    <div className="edit-category">
      <div className="modal-event">
        <form onSubmit={saveChanges}>
          <h2>{t("common:category.edit")}</h2>
          <span className="label">{t("common:category.name")}</span>
          {languages.map(renderNameInput)}
          <div className="name-actions-block">
            <CopyButton onClick={handleCopy} />
            <TranslateButton onClick={handleTranslate} />
          </div>
          <span className="label mt-15">{t("common:category.chooseColor")}</span>
          <div className="block-with-color">
            <BlockColor selectedColor={selectedColor} onSelectColor={setSelectedColor} />
          </div>
        </form>
      </div>

      <PlusIcon onClick={addRootSubCategory} className="add-button" />

      <SubCategories
        nestedIndex={1}
        subCategories={tree}
        onAddCategory={onAddSubCategory}
        onEditSubCategory={onEditSubCategory}
        onRemoveCategory={removeNode}
      />

      <div className="save-button">
        <Button variant="primary" onClick={saveChanges}>
          {t("button:save")}
        </Button>
        <Button variant="danger" onClick={deleteCategory}>
          Видалити
        </Button>
      </div>

      <AddSubCategoryModal ref={addCategoryModal} addSubCategory={addNode} />
      <EditSubCategoryModal ref={editCategoryModal} editSubCategory={editNode} />
      <MatchModal ref={addMatchModal} addSubCategory={addNode} editSubCategory={editNode} />
    </div>
  );
};
