import API from "../api";

export const createEventReq = (data) => API.post("/admin/event", data);

export const deleteEventReq = (id) => API.delete(`/admin/event/${id}`);

export const updateEventReq = (id, data) => API.put(`/admin/event/${id}`, data);

export const publishEventReq = (data) => API.post("/admin/event/publish", data);

export const expireEventReq = (data) => API.post("/admin/event/expire", data);

export const closeEventReq = (data) => API.post("/admin/event/close", data);

export const cancelEventReq = (data) => API.post("/admin/event/cancel", data);

export const getEventReq = (id) => API.get(`/admin/event/${id}`);

export const getFilteredEventReq = (params) =>
  API.get(`/admin/event/list`, {
    params: {
      ...params,
      size: params?.size || 6,
    },
  });

export const getAllEventReq = () =>
  API.get(`/admin/event/list?include=CREATED,PUBLISHED,ACTIVE,PASSIVE,FINISHED,EXPIRED,CLOSED,CANCELED`);

export const getFinishedEventReq = (params) =>
  API.get("/admin/event/list", {
    params: {
      ...params,
      include: "FINISHED",
      size: params?.size || 6,
    },
  });

export const getExpiredEventReq = () => API.get(`/admin/event/list?include=EXPIRED`);

export const getUnPublishEventReq = (query) =>
  API.get(`/admin/event/list?include=CREATED`, {
    params: {
      ...query,
      page: query?.page || 0,
      size: query?.size || 6,
    },
  });

export const stopEventReq = (eventUuid, reasonObject) => {
  const body = {
    eventUuid,
    cancelingInfo: reasonObject,
  };

  return API.put(`/admin/event/stop/${eventUuid}`, body);
};

export const delayEventExpiryReq = (eventId, body) => {
  return API.put(`/admin/event/expire/delay/${eventId}`, body);
};
