import "./pin-button.scss";

import React from "react";

import { ReactComponent as PinIcon } from "../../assets/svg/icon/pin.svg";

interface Props {
  isPinned?: boolean;

  onClick: () => void;
}

export const PinButton: React.FC<Props> = ({ isPinned, onClick }) => (
  <div className="pin-button" onClick={onClick}>
    <PinIcon className={isPinned ? "active" : ""} />
  </div>
);
