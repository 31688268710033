import React from "react";
import { Trans } from "react-i18next";
import cx from "classnames";

// @ts-ignore
import styles from "./statistic.module.scss";
// @ts-ignore
import { ReactComponent as DownloadIcon } from "../../assets/svg/icon/download_icon.svg";
import Calendar from "../../assets/controls/Calendar";
import { formatDateToCustomFormat } from "utils/date";
import ModalRangeDatePicker from "../../containers/ModalRangeDatePicker";
import { statisticService } from "api/statistic/statistic.service";

export const StatisticPage = () => {
  const [isCalendarOpen, setIsCalendarOpen] = React.useState(false);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);

  const isDownloadDisabled = !startDate || !endDate;

  const toggleCalendar = () => setIsCalendarOpen((isOpen) => !isOpen);

  const handleDateChange = ([start, end]: any) => {
    setStartDate(start);
    setEndDate(end);
  };

  const handleDownloadRegistrationStats = async () => {
    const url = await statisticService.getRegisteredUsers(startDate!, endDate!);

    const link = document.createElement("a");
    link.href = url;
    link.download = "registered_users.xlsx";

    link.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <div className={styles.statistic}>
      <h1 className={styles.pageTitle}>Реестрації користувачів</h1>

      <div className={styles.buttonRow}>
        <button className={styles["statistic__date-btn"]} onClick={toggleCalendar}>
          <Calendar />
          {formatDateToCustomFormat(startDate)} - {formatDateToCustomFormat(endDate)}
        </button>

        <button
          disabled={isDownloadDisabled}
          className={cx(styles.downloadButton, {
            [styles.availableDownloadIcon]: !isDownloadDisabled,
          })}
          onClick={handleDownloadRegistrationStats}
        >
          <DownloadIcon />
        </button>
      </div>

      {/* @ts-ignore */}
      <ModalRangeDatePicker
        isOpen={isCalendarOpen}
        setIsOpenModal={toggleCalendar}
        isUserHours
        // userId={userId}
        startDate={startDate}
        endDate={endDate}
        // @ts-ignore
        onDateChange={handleDateChange}
        // resetDates={resetDates}
        successHandler={toggleCalendar}
        cancelHandler={toggleCalendar}
        title={<Trans i18nKey="modals:chooseTime">Оберіть проміжок часу</Trans>}
      />
    </div>
  );
};
