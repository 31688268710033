export interface Node<T> {
  id: string;
  parentId: string | null;
  data: T;
  children: Node<T>[];
}

interface Localization {
  language: string;
  name: string;
}

export interface Match {
  team1: string;
  team2: string;
  time: string;
  localization?: MatchLocalization[];
}

export interface MatchLocalization {
  language: string;
  team1: string;
  team2: string;
  time: string;
}

export interface Category {
  categoryUuid: string;
  categoryName: string;
  categoryColor: string | null;
  localizations: Localization[];
  match?: Match;
}

export interface MainCategory {
  categoryUuid: string;
  categoryName: string;
  categoryColor: string | null;
  data: null | Category;
}

export const getAllCategoriesNodes =(nodes: Node<Category>[]) => {
  let result = new Map<string, Node<Category>>();

  const traverse = (node: Node<Category>) => {
    result.set(node.id, node);
    node.children.forEach((child) => traverse(child));
  }

  nodes.forEach((node) => traverse(node));

  return result;
};

export const buildNodeCategory = (parentId: string | null, category: Category) => ({
  id: category.categoryUuid,
  parentId,
  data: category,
  children: [],
});
