import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import classNames from "classnames/bind";
import styles from "./EventCard.module.scss";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  selectIsEventLoading,
  publishEvent,
  deleteEvent,
  cancelEvent,
  closeEvent,
  expireEvent,
  stopEvent,
  delayEventExpiry,
} from "store/slices/event";

// Icons
import DeleteIcon from "assets/svg/DeleteIcon";
import EditIcon from "assets/svg/EditIcon";

// Components and Containers
import TooltipComponent from "components/Tooltip";
import Button from "components/Button";
import TimeContainer from "components/TimeContainer";
import LangComponent from "components/LangComponent";
import ImageContainer from "containers/ImageContainer";
import { PinButton } from "components/PinButton/pin-button.component";
import ModalWarning, { ErrorText, SuccessText } from "containers/ModalWarning";
import ModalFormEvent from "containers/ModalFormEvent";
import ModalCancelDetail from "containers/ModalCancelDetail";
import ModalCancellingReason from "containers/ModalCancellingReason";
import ModalEditWinDirection from "containers/ModalEditWinDirection";
import ModalTimeAndDatePicker from "containers/ModalTimeAndDatePicker";
import { pinUnPinActiveEvent } from "api/event/events.api";

// Utils and Libs
import { NotificationManager } from "react-notifications";
import { parseDateToFormat } from "utils/date";
import { numberFormat } from "utils/numberFormat";
import moment from "moment";
import { Trans, useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

const cx = classNames.bind(styles);

export const EVENT_STATUS = {
  CREATED: "CREATED",
  PUBLISHED: "PUBLISHED",
  ACTIVE: "ACTIVE",
  PASSIVE: "PASSIVE",
  EXPIRED: "EXPIRED",
  FINISHED: "FINISHED",
  CLOSED: "CLOSED",
  CANCELED: "CANCELED",
};

const WIN_DIRECTION = {
  "expiration-yes": "BID_UP",
  "expiration-no": "BID_DOWN",
};

const EventCard = ({ item, isModalView, onSuccess = () => {}, getEvents, lang, content, type = "" }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(["button", "event", "modals", "notification"]);
  const isLoading = useSelector(selectIsEventLoading);
  const [winDirection, setWinDirection] = useState(null);
  const [individualLang, setIndividualLang] = useState(localStorage.getItem("lang"));
  const [isBetEndTimeValid, setIsBetEndTimeValid] = useState(false);
  const [isEventEndTimeValid, setIsEventEndTimeValid] = useState(false);
  const [isPinned, setIsPinned] = React.useState(!!item.isPinned);

  // Modal States
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [isOpenDetailModal, setOpenDetailModal] = useState(false);
  const [isOpenEditDirectionModal, setIsOpenEditDirectionModal] = useState(false);
  const [isBetEndTimePickerModalOpen, setIsBetEndTimePickerModalOpen] = useState(false);
  const [isEventEndTimePickerModalOpen, setIsEventEndTimePickerModalOpen] = useState(false);
  const [isCancellingReasonModalOpen, setIsCancellingReasonModalOpen] = useState(false);
  const [expirationModalData, setExpirationModalData] = useState(null);
  const [modalType, setModalType] = useState(null);
  const [reasonType, setReasonType] = useState(null);

  let displayedContent;

  if (content) {
    displayedContent = content[individualLang].find((obj) => obj.uuid === item.uuid);
  } else if (isModalView) {
    displayedContent = item;
  }

  const { control, getValues, formState, setValue, watch } = useForm({
    reValidateMode: "onChange",
    mode: "onTouched",
    defaultValues: {
      betEndTime: new Date(),
      eventEndTime: new Date(),
      currentDateAndTime: new Date(),
    },
  });

  const { errors, isValid } = formState;

  const betEndTime = watch("betEndTime");
  const eventEndTime = watch("eventEndTime");
  const currentDateTime = watch("currentDateAndTime");

  const togglePinEvent = () => {
    setIsPinned((isPinned) => {
      pinUnPinActiveEvent(item.uuid, !isPinned);

      return !isPinned
    });
  }

  const ValidateDatePickers = () => {
    if (isValid) {
      const betEnd = moment.utc(betEndTime);
      const eventFinish = moment.utc(eventEndTime);
      const now = moment.utc(currentDateTime);

      setIsBetEndTimeValid(betEnd > now);
      setIsEventEndTimeValid(eventFinish > betEnd);
    }
  };

  useEffect(() => {
    ValidateDatePickers();
  }, [betEndTime, eventEndTime, isValid]);

  const openModal = (type) => {
    setModalType(type);

    if (type === "detail") {
      return setOpenDetailModal(true);
    }
    if (type === "edit-exp") {
      return setIsOpenEditDirectionModal(true);
    }

    return setIsWarningModalOpen(true);
  };

  const editEvent = (uuid) => {
    if (displayedContent?.status === EVENT_STATUS.CREATED) {
      navigate(`/events/unpublished-event/edit/${uuid}`);
    }
    if (displayedContent?.status === EVENT_STATUS.PUBLISHED) {
      navigate(`/events/published/edit/${uuid}`);
    }
  };

  const publish = () => {
    setModalType(null);
    dispatch(
      publishEvent({
        eventUuid: displayedContent?.uuid,
      }),
    )
      .unwrap()
      .then(() => {
        onSuccess();
      })
      .catch((err) => {
        NotificationManager.error("", t("notification:errorPublishTitle"), 6000);
      });
  };

  const deleteHandler = () => {
    setModalType(null);
    dispatch(deleteEvent({ id: displayedContent?.uuid }))
      .unwrap()
      .then(() => {
        NotificationManager.success("", t("notification:succesDeleteTitle"), 6000);
        onSuccess();
      })
      .catch((err) => {
        NotificationManager.error("", t("notification:errorDeleteTitle"), 6000);
      });
  };

  const cancelHandler = (data) => {
    setModalType(null);
    setIsOpenFormModal(false);
    dispatch(
      cancelEvent({
        eventUuid: displayedContent?.uuid,
        cancelingInfo: data,
      }),
    )
      .unwrap()
      .then(() => {
        onSuccess();
      })
      .catch((err) => {
        NotificationManager.error("", t("notification:errorCancelTitle"), 6000);
      });
  };

  const setExpirate = () => {
    setModalType(null);
    setIsOpenFormModal(false);
    dispatch(
      expireEvent({
        eventUuid: displayedContent?.uuid,
        expirationReference: expirationModalData,
        winDirection,
        type,
      }),
    )
      .unwrap()
      .then(() => {
        onSuccess();
      })
      .catch((err) => {
        NotificationManager.error("", t("notification:errorExpirationTitle"), 6000);
      });
  };

  const calculateEvent = () => {
    setModalType(null);
    setIsOpenFormModal(false);
    dispatch(
      closeEvent({
        eventUuid: displayedContent?.uuid,
      }),
    )
      .unwrap()
      .then(() => {
        onSuccess();
      })
      .catch((err) => {
        NotificationManager.error("", t("notification:errorCalculateTitle"), 6000);
      });
  };

  const resetTimePickers = () => {
    setValue("betEndTime", new Date());
    setValue("eventEndTime", new Date());
  };

  const warningModalSuccessBtnHandler = () => {
    if (modalType === "publish") {
      return publish();
    }
    if (modalType === "cancel") {
      setIsWarningModalOpen(false);
      return setIsOpenFormModal(true);
    }
    if (modalType === "expiration-yes" || modalType === "expiration-no") {
      setIsWarningModalOpen(false);
      setWinDirection(WIN_DIRECTION[modalType]);
      setModalType("edit-exp");
      return setIsOpenFormModal(true);
    }
    if (modalType === "edit-exp") {
      setIsWarningModalOpen(false);
      return setExpirate();
    }
    if (modalType === "calculate") {
      return calculateEvent();
    }
    if (modalType === "cancelBid") {
      setIsWarningModalOpen(false);
      setReasonType("cancelBid");
      return setIsCancellingReasonModalOpen(true);
    }
    deleteHandler();
  };

  const successFormModalHandler = (data) => {
    if (modalType === "cancel") {
      return cancelHandler(data);
    }
    if (modalType === "edit-exp" || modalType === "expiration-yes" || modalType === "expiration-no") {
      setExpirationModalData(data);
      setIsWarningModalOpen(true);
    }
  };

  const TranslatedReasonModalHandler = (reasonObject) => {
    switch (reasonType) {
      case "cancelBid":
        dispatch(
          stopEvent({
            eventUuid: displayedContent?.uuid,
            reasonObject,
          }),
        );
        setReasonType(null);
        break;

      case "delayExpiry":
        const data = {
          eventDTO: {
            ...displayedContent,
            betEndTime: parseDateToFormat(getValues().betEndTime, "YYYY-MM-DDTHH:mm:ssZ"),
            finishTime: parseDateToFormat(getValues().eventEndTime, "YYYY-MM-DDTHH:mm:ssZ"),
            status: "ACTIVE",
          },

          cancelingInfo: reasonObject,
        };

        dispatch(delayEventExpiry(data));
        resetTimePickers();
        setReasonType(null);
        break;

      default:
        break;
    }

    // there is a possibility that we will need to add the dispatch getFilteredEvent action to update the active or expired events page immediately after dispatching the first action
  };

  // Functions created to render component JSX according to dynamic conditions
  const renderImg = () => {
    if (!isModalView) {
      return (
        <div className={cx("card__img")}>
          <ImageContainer imageLink={displayedContent?.imageLink} imageUuid={displayedContent?.imageUuid} />
        </div>
      );
    }
    return null;
  };

  const renderVotingBtns = () => {
    if (displayedContent?.status !== EVENT_STATUS.PUBLISHED && displayedContent?.status !== EVENT_STATUS.CREATED) {
      return (
        <div
          className={cx("card__vote", {
            active: displayedContent?.status === EVENT_STATUS.FINISHED,
            "no-interactive":
              displayedContent?.status === EVENT_STATUS.ACTIVE || displayedContent?.status === EVENT_STATUS.PASSIVE,
          })}
        >
          <div
            className={cx("card__vote-yes", {
              selected: displayedContent?.expirationValue === "BID_UP",
            })}
            onClick={() => {
              if (displayedContent?.status === EVENT_STATUS.FINISHED) {
                openModal("expiration-yes");
              }
            }}
          >
            <span>{t("event:bidYes")}</span>
            <span>{displayedContent?.bidUpCoefficient}x</span>
          </div>
          <div
            className={cx("card__vote-no", {
              selected: displayedContent?.expirationValue === "BID_DOWN",
            })}
            onClick={() => {
              if (displayedContent?.status === EVENT_STATUS.FINISHED) {
                openModal("expiration-no");
              }
            }}
          >
            <span>{t("event:bidNo")}</span>
            <span>{displayedContent?.bidDownCoefficient}x</span>
          </div>
        </div>
      );
    }

    return null;
  };

  const renderCancelBtn = () => {
    if (
      displayedContent?.status === EVENT_STATUS.PUBLISHED ||
      displayedContent?.status === EVENT_STATUS.FINISHED ||
      displayedContent?.status === EVENT_STATUS.ACTIVE ||
      displayedContent?.status === EVENT_STATUS.PASSIVE
    ) {
      return (
        <Button
          disabled={isLoading}
          onClick={() => {
            openModal("cancel");
          }}
        >
          {displayedContent?.status === EVENT_STATUS.FINISHED ? t("button:cancel") : t("button:cancelEvent")}
        </Button>
      );
    }

    return null;
  };

  const renderCardMainBtns = () => {
    if (displayedContent?.status === EVENT_STATUS.CREATED) {
      return (
        <Button
          disabled={isLoading}
          onClick={() => {
            openModal("publish");
          }}
        >
          {t("button:publish")}
        </Button>
      );
    }

    if (displayedContent?.status === EVENT_STATUS.ACTIVE) {
      return (
        <Button
          disabled={isLoading}
          onClick={() => {
            openModal("cancelBid");
          }}
          styleBtn={{ color: "#ff0000" }}
        >
          {t("button:cancelBid")}
        </Button>
      );
    }

    if (displayedContent?.status === EVENT_STATUS.CLOSED) {
      return <Button disabled>{t("button:finishedEvent")}</Button>;
    }

    if (displayedContent?.status === EVENT_STATUS.CANCELED) {
      return (
        <Button
          onClick={() => {
            openModal("detail");
          }}
        >
          {t("button:detail")}
        </Button>
      );
    }

    if (displayedContent?.status === EVENT_STATUS.EXPIRED) {
      return (
        <>
          <Button
            onClick={() => {
              openModal("calculate");
            }}
          >
            {t("button:calculate")}
          </Button>
          <Button
            onClick={() => {
              openModal("edit-exp");
            }}
          >
            {t("button:editExp")}
          </Button>
        </>
      );
    }

    if (displayedContent?.status === EVENT_STATUS.FINISHED) {
      return (
        <Button
          disabled={isLoading}
          onClick={() => {
            setIsBetEndTimePickerModalOpen(true);
            setValue("currentDateAndTime", new Date());
            setValue("betEndTime", new Date());
            setValue("eventEndTime", new Date());
          }}
        >
          {t("button:delayExpiry")}
        </Button>
      );
    }

    return null;
  };

  const renderLangBarAndCardBtns = () => {
    if (!isModalView) {
      return (
        <>
          <LangComponent
            getEvents={getEvents}
            isEventCard
            individualLang={individualLang}
            setIndividualLang={setIndividualLang}
          />

          <div
            className={cx("card__button", {
              "flex-direction": displayedContent?.status === EVENT_STATUS.ACTIVE,
            })}
          >
            <div className={cx("card__button__pin_wrapper")}>
              <PinButton isPinned={isPinned} onClick={togglePinEvent} />
              <div style={{ width: '100%', alignSelf: 'center' }}>
                {renderCancelBtn()}
              </div>
            </div>
            {renderCardMainBtns()}
          </div>
        </>
      );
    }

    return;
  };

  const generateWarningModalText = () => {
    if (modalType === "publish" || modalType === "edit-exp") {
      return (
        <Trans i18nKey="modals:allEnteredDataCorrect">
          Ти точно впевнений, що всі <ErrorText>ДАНІ ПРАВИЛЬНО ВВЕДЕНІ</ErrorText>?
        </Trans>
      );
    }

    if (modalType === "expiration-yes") {
      return (
        <Trans i18nKey="modals:bidYesEvent">
          Ти точно впевнений, що перемогла позиція
          <SuccessText isUpperCase>Так</SuccessText>?
        </Trans>
      );
    }

    if (modalType === "expiration-no") {
      return (
        <Trans i18nKey="modals:bidNoEvent">
          Ти точно впевнений, що перемогла позиція <ErrorText isUpperCase>Ні</ErrorText>?
        </Trans>
      );
    }

    if (modalType === "calculate") {
      return (
        <Trans i18nKey="modals:calculateEvent">
          Ти точно впевнений, що можна <SuccessText>розраховувати</SuccessText>?
        </Trans>
      );
    }

    if (modalType === "cancelBid") {
      return (
        <Trans i18nKey="modals:cancelBid">
          Ти хочешь<ErrorText>СКАСУВАТИ ПРИЙМАННЯ СТАВОК?</ErrorText>
        </Trans>
      );
    }

    return (
      <Trans i18nKey="modals:cancelEvent">
        Ти точно впевнений, що хочеш <ErrorText>СКАСУВАТИ ПОДІЮ</ErrorText>?
      </Trans>
    );
  };

  return (
    <div
      className={cx("card", {
        "modal-view": isModalView,
      })}
    >
      <div className={cx("card__content")}>
        {renderImg()}

        <div className={cx("card__action")}>
          <TimeContainer
            // createTime={"01 Jan 1970 00:00:00 GMT"}
            createTime={moment(displayedContent?.startTime).subtract(10, "day")}
            startTime={displayedContent?.startTime}
            betEndTime={displayedContent?.betEndTime}
            finishTime={displayedContent?.finishTime}
            isTimerDisabled={displayedContent?.status === EVENT_STATUS.CANCELED}
            eventStatus={displayedContent?.status}
          />

          {displayedContent?.status !== EVENT_STATUS.PUBLISHED && displayedContent?.status !== EVENT_STATUS.CREATED ? (
            <div className={cx("card__amount")}>
              <span>{t("event:commonPool")}</span>
              <span>{displayedContent?.totalAmount} &euro;</span>
            </div>
          ) : (
            <div className={cx("card__btns")}>
              <div className={"card__btn"}>
                {displayedContent?.status === EVENT_STATUS.CREATED && (
                  <DeleteIcon
                    className={cx("card__btn-icon")}
                    onClick={() => {
                      openModal("delete");
                    }}
                  />
                )}
                {(displayedContent?.status === EVENT_STATUS.PUBLISHED ||
                  displayedContent?.status === EVENT_STATUS.CREATED) && (
                  <EditIcon className={cx("card__btn-icon")} onClick={() => editEvent(displayedContent?.uuid)} />
                )}
              </div>
            </div>
          )}
        </div>

        {renderVotingBtns()}

        <div className={cx("card__info")}>
          <div className={cx("card__info-row")}>
            <span>{t("event:subCategory")}</span>
            <span>{displayedContent?.subCategory}</span>
          </div>
          <div className={cx("card__info-row")}>
            <span>{t("event:eventDescription")}</span>
            <span>
              <TooltipComponent
                line={2}
                count={50}
                trigger={<span className={cx("text")}>{displayedContent?.title}</span>}
                textTooltip={displayedContent?.title}
              />
            </span>
          </div>
          <div className={cx("card__info-row")}>
            <span>{t("event:eventAdditionalInfo")}</span>
            <span>
              <TooltipComponent
                line={2}
                count={50}
                trigger={<span className={cx("text")}>{displayedContent?.additionalInfo}</span>}
                textTooltip={displayedContent?.additionalInfo}
              />
            </span>
          </div>
          <div className={cx("card__info-row")}>
            <span>{t("event:startTime")}</span>
            <span>{parseDateToFormat(displayedContent?.startTime, "DD.MM.YYYY HH:mm UTCZ")}</span>
          </div>
          <div className={cx("card__info-row")}>
            <span>{t("event:betEndTime")}</span>
            <span>{parseDateToFormat(displayedContent?.betEndTime, "DD.MM.YYYY HH:mm UTCZ")}</span>
          </div>
          <div className={cx("card__info-row")}>
            <span>{t("event:finishTime")}</span>
            <span>{parseDateToFormat(displayedContent?.finishTime, "DD.MM.YYYY HH:mm UTCZ")}</span>
          </div>
          <div className={cx("card__info-row")}>
            <span>{t("event:startReference")}</span>
            <span>{numberFormat(displayedContent?.startReference)}</span>
          </div>
          <div className={cx("card__info-row")}>
            <span>{t("event:controlReference")}</span>
            <span>{numberFormat(displayedContent?.controlReference)}</span>
          </div>
          {(displayedContent?.status === EVENT_STATUS.EXPIRED ||
            displayedContent?.status === EVENT_STATUS.CLOSED ||
            displayedContent?.status === EVENT_STATUS.CANCELED) && (
            <div className={cx("card__info-row")}>
              <span>{t("event:expirationValue")}</span>
              <span>
                {displayedContent?.expirationReference === null ? "-" : displayedContent?.expirationReference}
              </span>
            </div>
          )}
        </div>

        {renderLangBarAndCardBtns()}

        {/* Modals associated to all use cases of Event Cards */}
        <ModalWarning
          isOpen={isWarningModalOpen}
          setIsOpenModal={() => setIsWarningModalOpen(!isWarningModalOpen)}
          cancelHandler={() => setModalType(null)}
          successHandler={warningModalSuccessBtnHandler}
          type={modalType}
          title={generateWarningModalText()}
        />

        <ModalEditWinDirection
          isOpen={isOpenEditDirectionModal}
          description={displayedContent?.cancelingInfo}
          bidUpCoefficient={displayedContent?.bidUpCoefficient}
          bidDownCoefficient={displayedContent?.bidDownCoefficient}
          setIsOpenModal={() => setIsOpenEditDirectionModal(!isOpenEditDirectionModal)}
          successHandler={(res) => {
            setWinDirection(res);
            setIsOpenFormModal(true);
          }}
          closeHandler={() => {
            setModalType(null);
          }}
        />

        <ModalFormEvent
          title={modalType === "cancel" ? t("modals:cancelReason") : t("modals:enterExpValue")}
          isOpen={isOpenFormModal}
          setIsOpenModal={() => setIsOpenFormModal(!isOpenFormModal)}
          successHandler={successFormModalHandler}
          cancelHandler={() => {
            setModalType(null);
          }}
        />

        <ModalCancelDetail
          isOpen={isOpenDetailModal}
          description={displayedContent?.cancelingInfo}
          data={{
            subCategory: displayedContent?.subCategory,
            title: displayedContent?.title,
          }}
          setIsOpenModal={() => setOpenDetailModal(!isOpenDetailModal)}
          closeHandler={() => {
            setModalType(null);
          }}
        />

        <ModalCancellingReason
          isOpen={isCancellingReasonModalOpen}
          setIsOpenModal={() => setIsCancellingReasonModalOpen(!isCancellingReasonModalOpen)}
          reasonType={reasonType}
          successHandler={TranslatedReasonModalHandler}
          cancelHandler={() => {
            setReasonType(null);
            setModalType(null);
          }}
        />

        <ModalTimeAndDatePicker
          isOpen={isBetEndTimePickerModalOpen}
          setIsOpenModal={() => setIsBetEndTimePickerModalOpen(!isBetEndTimePickerModalOpen)}
          control={control}
          errors={errors}
          name="betEndTime"
          successHandler={() => {
            setIsBetEndTimePickerModalOpen(!isBetEndTimePickerModalOpen);
            setIsEventEndTimePickerModalOpen(!isEventEndTimePickerModalOpen);
          }}
          cancelHandler={() => {
            resetTimePickers();
            setIsBetEndTimePickerModalOpen(!isBetEndTimePickerModalOpen);
          }}
          title="Час закінчення прийому ставок"
          isDatePickerValueValid={isBetEndTimeValid}
        />

        <ModalTimeAndDatePicker
          isOpen={isEventEndTimePickerModalOpen}
          setIsOpenModal={() => setIsEventEndTimePickerModalOpen(!isEventEndTimePickerModalOpen)}
          control={control}
          errors={errors}
          name="eventEndTime"
          successHandler={() => {
            setIsEventEndTimePickerModalOpen(!isEventEndTimePickerModalOpen);
            setReasonType("delayExpiry");
            setIsCancellingReasonModalOpen(!isCancellingReasonModalOpen);
          }}
          cancelHandler={() => {
            resetTimePickers();
            setIsEventEndTimePickerModalOpen(!isEventEndTimePickerModalOpen);
          }}
          title="Час закінчення події"
          isDatePickerValueValid={isEventEndTimeValid}
        />
      </div>
    </div>
  );
};

export default EventCard;
