import "./sub-categories-tree.scss";

import React from "react";
import { useDispatch, useSelector } from "react-redux";

import StepSave from "containers/StepSave";
import { Node, Category } from "pages/Events/EditCategory/tree";
import { selectSelectedEventCategoryId } from "store/slices/eventCategory";
import { SubCategoriesSelect } from "./sub-categories-select/sub-categories-select.component";
import { markItemAsValid, markItemAsVisited, saveStepPageToStore, selectStepsPages, setStepPageData } from "store/slices/steps";

interface Props {
  id: number;
  categoriesTree: Node<Category>[];
}

export const SubCategoriesTree: React.FC<Props> = ({ id, categoriesTree }) => {
  const dispatch = useDispatch();
  const storedData = useSelector(selectStepsPages);
  const selectedCategory = useSelector(selectSelectedEventCategoryId);

  const [isOpenModal, setIsOpenModal] = React.useState(false);
  const [selectedSubCategoryId, setSelectedSubCategoryId] = React.useState<string | null>(storedData[id - 1].value);

  const handleSelect = (categoryId: any) => {
    const newCategoryId = categoryId === selectedSubCategoryId ? null : categoryId;

    dispatch(markItemAsVisited(id));
    setSelectedSubCategoryId(newCategoryId);
    dispatch(markItemAsValid(id, !!newCategoryId));
    dispatch(setStepPageData(id, newCategoryId));
  };

  const handleSaveStep = () => {
    if (!!selectedSubCategoryId) {
      dispatch(saveStepPageToStore(id, selectedCategory));
    }
  };

  return (
    <div className="sub-categories-tree">
      <div className="subcategory-step__head">
        <StepSave
          isOpenModalOuter={isOpenModal}
          // @ts-ignore
          setIsOpenModalOuter={setIsOpenModal}
          isSuccess={!!selectedSubCategoryId}
          successHandler={handleSaveStep}
        />
      </div>

      <SubCategoriesSelect
        nestedIndex={1}
        selectedCategoryId={selectedSubCategoryId}
        subCategories={categoriesTree}
        onSelectCategory={handleSelect}
      />
    </div>
  );
};
